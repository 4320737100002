<template lang="pug">
div
  slot(name="activator" :toggle="toggle")
    v-btn(small color='primary'
      @click="toggle"
      :disabled="disabled"
      outlined
    ) {{ activatorText }}
  v-dialog(v-model="showing" scrollable max-width=400)
    courseChooseProvider(:item="formData" @input:course="updateCourse")
      template(v-slot:default="courseChoose")
        v-card
          v-card-title {{ title }}
          v-card-text
            filter-autocomplete(
              :value="formData.course.city"
              @input="courseChoose.loadCoursesDatesByLocation"
              :getItems="getCourseLocation"
              label="Course Location"
              item-value="ID"
              item-text="name"
              :error="formErrors.hasError('courseLocation')"
              :error-messages="formErrors.fields.courseLocation"
            )
            v-autocomplete(
              :value="formData.course.ID"
              @input="setCourseHandler($event, courseChoose.courses)"
              label="Course"
              :loading="courseChoose.loading"
              :disabled="!courseChoose.courses.length"
              :items="courseChoose.courses"
              item-text="dateAndName"
              item-value="ID"
              return-object
              :error="formErrors.hasError('courseDate')"
              :error-messages="formErrors.fields.courseDate"
            )
          v-card-actions
            v-spacer
            v-btn(color="error" text @click="toggle") Close
            showingProvider
              template(v-slot:default="provider")
                v-dialog(max-width="370px" :value="provider.showing" @input="provider.updateShowing")
                  template(v-slot:activator="remove")
                    v-btn(color="warning" text v-if="isCanBeRemoved" v-on="remove.on") Remove
                  v-card
                    v-card-title Additional course will be removed
                    v-card-text You wanna to delete additional course?
                    v-card-actions
                      v-spacer
                      v-btn(color="error" text @click="provider.toggle") cancel
                      v-btn(color="success" text  @click="remove") Remove

            v-btn(color="success" text @click="submit") Add
</template>

<script>
import { mapActions } from 'vuex'
import errorsMixin from '@/mixins/errors.mixin'
import FormErrors from '@/util/form-errors'
import CourseModel from '../../../models/courseModel'

export default {
  mixins: [errorsMixin],
  props: {
    disabled: Boolean,
    activeItems: Array,
    title: {
      type: String,
      default: 'Change course'
    },
    activatorText: {
      type: String,
      default: 'Change course'
    },
    isCustomSend: {
      default: false,
      type: Boolean
    },
    isCanBeRemoved: Boolean
  },
  data: () => ({
    formData: {
      course: new CourseModel(),
    },
    showing: false,
    formErrors: new FormErrors(),
    apiAttrs: {
      course_id: 'courseDate',
    }
  }),

  inject: ['svc'],

  methods: {
    ...mapActions({
      getCourseLocation: 'crmCourseCities/list',
    }),

    toggle() {
      this.showing = !this.showing
    },

    submit() {
      if(!this.validate(this.formErrors)) {
        this.$notify({text: 'Invalid fields', type: 'error'})
        return
      }
      if(this.isCustomSend ) return this.$emit('submit', {
        activeItems: this.activeItems,
        formErrors: this.formErrors,
        course: this.formData.course,
        toggle: this.toggle
      })
      this.send()
    },

    validate(validator) {
      validator.resetFields()
      this.validateCourse(this.formData.course, validator)
      return validator.isValid()
    },

    validateCourse(course, validator) {
      if(!course.ID) {
        validator.invalidField('courseDate', 'field required')
      }
      if(!course.city) {
        validator.invalidField('courseLocation', 'field required')
      }
    },

    async send(payload) {
      try {
        let ids = this.activeItems.map(item => item.ID)
        let res = await this.svc().changeCourseBulk(ids, this.formData)
        this.$notify({text: 'Candidates course has been changed', type: 'success'})
        this.toggle()
        this.$emit('send', res)
      } catch (error) {
        console.log(error)
        this.processErrorWithForm(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          formErrors: this.formErrors,
          apiAttributes: this.apiAttrs
        });
      }
    },

    remove() {
      return this.$emit('remove', {
        activeItems: this.activeItems,
        formErrors: this.formErrors,
        course: new CourseModel({
          id: null
        }),
        toggle: this.toggle
      })
    },

    reset() {
      this.formData = {
        course: new CourseModel(),
      }
    },

    updateCourse(course) {
      this.formData.course = course
    },

    setCourseHandler(course, courses) {
      this.formData.course = course
    },
  },

  watch: {
    showing(isShowing) {
      if(!isShowing) {
        this.reset()
        return
      }
    }
  },

  components: {
    filterAutocomplete: () => import('@/components/global/FilterAutocomplete.vue'),
    CourseChooseProvider: () => import('./reservation/CandidateCourseChooseProvider.vue'),
    showingProvider: () => import('@/components/renderless/ShowingProvider.vue')
  }
}
</script>
